export const versions = {
  globals: [
    {
      key: '@angular/cli',
      data: '10.1.7'
    },
    {
      key: 'OS',
      data: 'win32 x64 10.0.19043'
    },
    {
      key: 'Node',
      data: '14.17.0'
    }
  ],
  locals: [
    {
      key: '@angular/cli',
      data: '12.2.12'
    },
    {
      key: '@angular/core',
      data: '12.2.12'
    },
    {
      key: '@angular/material',
      data: '12.2.12'
    },
    {
      key: '@angular/cdk',
      data: '12.2.12'
    },
    {
      key: '@angular/flex-layout',
      data: '12.0.0-beta.35'
    },
    {
      key: '@angular/fire',
      data: '7.1.1'
    },
    {
      key: 'firebase',
      data: '9.4.1'
    },
    {
      key: 'rxjs',
      data: '7.4.0'
    }
  ],
  date: '2021-11-29T16:08:49.981 UTC+0800',
  sha: 'b18b745ede7584719a62df093a12e1af388050d2'
};
