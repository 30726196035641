import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
// import { HttpModule } from '@angular/http';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { AccountComponent } from './account/account.component';
import { MailComponent } from './mail/mail.component';

// import { CheckphpService } from './checkphp.service';

@NgModule({
  imports: [
    SharedModule
    , AuthRoutingModule
  ]
  , declarations: [
    LoginComponent
    , AccountComponent
    , MailComponent
  ]
  , providers: [
//    CheckphpService
  ]
  /*
   * check PHP service is only used by Account Component
   * so it is provided here instead of at App/Core Module
   * it might as well be provided by Account Component
   */
})
export class AuthModule { }
