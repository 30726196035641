import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { MidGuardService } from './../app-routing/mid-guard.service';
import { HomeComponent } from './home.component';

const routes: Routes = [
  {
    path: 'home'
    , component: HomeComponent
    , canActivate: [ MidGuardService ]
  }
];

@NgModule({
  imports: [
    CommonModule
    , RouterModule.forChild(routes)
  ]
  , exports: [ RouterModule ]
})
export class HomeRoutingModule { }
