import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// import { auth } from 'firebase/app';
// import type { User } from '@firebase/auth-types';
// import { AngularFireAuth } from '@angular/fire/compat/auth';
// import { auth } from '@angular/fire/compat';
import { Auth, authState, User } from '@angular/fire/auth';

import { Observable,  ReplaySubject } from 'rxjs';

export interface IUser {
  // firebase user info
  uid: string;
  name: string;
  email: string;
  provider: string;
  verified: boolean;
  // custom claim
  mid: number;
  aid: number;
  isAdmin: boolean;
  isServer: boolean;
}

export interface IClaim {
  aid?: number;
  mid?: number;
  admin?: boolean;
  server?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public get isAuth(): boolean { return this._user.uid !== ''; }
  public get user(): IUser { return this._user; }
  public get user$(): Observable<IUser> {return this._user$.asObservable(); }

  private readonly _emptyUser: Readonly<IUser> = {
    // firebase user info
    uid: '',
    name: '',
    email: '',
    provider: '',
    verified: false,
    // registry info
    mid: 0,
    aid: 0,
    isAdmin: false,
    isServer: false
  };
  private _user: IUser = this._emptyUser;
  private _user$ = new ReplaySubject<IUser>(1);
  // private _destroy$ = new Subject<boolean>();

  private _cleanup: (() => Promise<boolean>) | null = null;

  constructor(
    private _afAuth: Auth
    , private _router: Router
  ) {
    authState(this._afAuth).subscribe((fbuser: User | null) => {
      this._loadClaim(fbuser);
    });
  }

  private _loadClaim(fbuser: User | null, force = false) {
    if (fbuser) this.customClaim(fbuser, force).then((payload: IClaim) => {
      this._user$.next(this._user = {
        // firebase user info
        uid: fbuser.uid,
        name: fbuser.providerData[0].displayName || 'no name',
        email: fbuser.providerData[0].email ?? '',
        provider: fbuser.providerData[0].providerId,
        // verified: fbuser.providerData[0].providerId === 'facebook.com' || fbuser.emailVerified,
        verified: fbuser.emailVerified,
        // custom claim info
        mid: payload.mid ?? 0,
        aid: payload.aid ?? 0,
        isAdmin: (payload.admin === true), // normally null
        isServer: (payload.server === true) // normally null
      });
    });
    else this._user$.next(this._emptyUser);
  }

  public reloadClaim() {
    // this._loadClaim(this._afAuth.currentUser, true);
    this._loadClaim(this._afAuth.currentUser, true);
  }

  /**
   * extract custom claim
   */
  public customClaim(fbuser: User, force = false): Promise<IClaim> {
    return new Promise<IClaim>((resolve, reject) => {
      if (fbuser) {
        fbuser.getIdTokenResult(force).then(r => {
          resolve(r.claims as IClaim);
        }).catch(_ => resolve({}));
      } else reject('unauthenticated');
    });
  }

  /**
   * called by login and account
   * required because changes in name do not trigger authState change
   */
  public nameChanged() {
    const u = this._afAuth.currentUser;
    if (u) {
      if (this._user.name !== u.displayName) {
        this._user.name = u.displayName ?? '';
        this._user$.next(this._user);
      }
    }
  }

  //
  // only support one active component to register a cleanup function
  //
  public regCleanup(f: () => Promise<boolean>) {
    this._cleanup = f;
  }

  public unregCleanup() {
    this._cleanup = null;
  }

  public logout() {
    if (this._cleanup) {
      this._cleanup().then(() => {
        this._cleanup = null;
        this._afAuth.signOut();
        this._router.navigate(['/login']);
      });
    } else {
      this._afAuth.signOut();
      this._router.navigate(['/login']);
    }
  }
}

