import { Injectable } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, map, distinctUntilChanged, tap } from 'rxjs/operators';
import { Alias } from './my-break-points.module';

@Injectable({
  providedIn: 'root'
})
export class MediaQueryService {
  private _alias$: ReplaySubject<Alias> = new ReplaySubject(1);
  // if it is a regular subject, components will always miss the initial value
  // if I use behavior subject, then I need to emit a fake initial value
  private _alias!: Alias;
  public get alias(): Alias { return this._alias; }
  public get alias$(): Observable<Alias> { return this._alias$.asObservable(); }

  constructor(
    // private _media$: ObservableMedia
    mo: MediaObserver
  ) {
    // old interface up to 700-beta.23
    // _mo.media$.pipe(
    //   pluck<MediaChange, string>('mqAlias'),
    //   // .takeUntil(this._destroy$)
    //   distinctUntilChanged()
    // ).subscribe(this._alias);

    // new interface 700-beta.24
    // now returns an array of all matched aliases (include gt-xx and lt-xx)
    // the first entry is the one we want (xs, sm etc)
    mo.asObservable().pipe(
      filter((changes: MediaChange[]) => changes.length > 0)
      , map((changes: MediaChange[]) => changes[0].mqAlias as Alias)
      , distinctUntilChanged()
      , tap(a => {this._alias = a; })
    ).subscribe(this._alias$);

    // no longer necessary. The initial value is now emited correctly very soon
    // if (!this._alias.getValue()) {
    //   for (const mq of ['xs', 'sm', 'md', 'lg', 'xl']) {
    //     if (_mo.isActive(mq)) this._alias.next(mq);
    //   }
    // }
  }

  /**
   * re-exports the isActive of ObservableMedia
   * returns true if the query is active
   * supports the non standard queries defined in my-break-point
   * not called by anybody now
   *
   * @param query query string
   */
  // public isActive(query: string): boolean {
  //   return this._mo.isActive(query);
  // }
}
