import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
// import { User } from '@firebase/auth-types';
// import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Auth, authState } from '@angular/fire/auth';

import { take } from 'rxjs/operators';

import { GuardService } from './guard.service';
import { AuthService } from 'app/core/auth.service';

@Injectable()

export class MidGuardService implements CanActivate {
  constructor(
    private router: Router
    , private _afAuth: Auth
    , private _gs: GuardService
    , private _as: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return new Promise<boolean | UrlTree>((resolve) => {
      authState(this._afAuth).pipe(take(1)).subscribe((fbuser) => {
        if (fbuser) {
          this._as.customClaim(fbuser)
          .then(claim => {
            if (claim.mid) resolve(true);
            else {
              // authenticated but without mid
              resolve(this._gs.notYetAuth(this.router, route, '/account'));
            }
          }).catch(_ => {
            resolve(this._gs.notYetAuth(this.router, route, '/account'));
          });
        } else {
          // not yet authenticated
          resolve(this._gs.notYetAuth(this.router, route));
        }
      });
    });
  }

}
