// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

// import { AngularFireModule, AuthMethods } from '@angular/fire/compat';

// // Must export the config
// export const firebaseConfig = {
//   apiKey: 'AIzaSyD-Xos9n3uOV2hZql389c5gpSDc_SLe1vk'
//   , authDomain: 'bank-test-3aca5.firebaseapp.com'
//   , databaseURL: 'https://bank-test-3aca5.firebaseio.com'
//   , projectId: 'bank-test-3aca5'
//   , storageBucket: 'bank-test-3aca5.appspot.com'
//   // ,messagingSenderId: '136165814777'
// };

// const firebaseAuthConfig = {
//   method: AuthMethods.Popup,
//   remember: 'default'
// };

// export const FirebaseModule = AngularFireModule.initializeApp(firebaseConfig, firebaseAuthConfig);

export const environment = {
  production: false
  , firebase: {
    apiKey: 'AIzaSyD-Xos9n3uOV2hZql389c5gpSDc_SLe1vk'
    , authDomain: 'bank-test-3aca5.firebaseapp.com'
    , databaseURL: 'https://bank-test-3aca5.firebaseio.com'
    , projectId: 'bank-test-3aca5'
    , storageBucket: 'bank-test-3aca5.appspot.com'
    // ,messagingSenderId: '136165814777'
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  , TCHdomain: 'http://bankuat.alexfung.info/'
};
