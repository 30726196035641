<mat-toolbar class="mat-grey-appbar">
  <span>Welcome</span>
</mat-toolbar>

<mat-tab-group [selectedIndex]='tabgroupInitial'>
  <mat-tab>
    <ng-template matTabLabel>
      <span fxShow fxHide.gt-sm>Social</span>
      <span fxHide fxShow.gt-sm>Sign in</span>
    </ng-template>
    <div fxLayout="row wrap">
      <mat-card fxFlex="0 1 18em" fxFlex.xs='100' fxFlexOrder='2'>
        <mat-card-header class="md-primary md-hue-1">
          <b>Federated identity</b>
        </mat-card-header>
        <mat-card-content>
          <button mat-raised-button (click)="loginGoogle(false)" style="width:240px; text-align: left">
            <img width="30" style="padding-right:10px" src="/assets/google-logo.png" />Use My Google Account
          </button>
          <br/><br/>
          <button mat-raised-button (click)="loginFacebook(false)" style="width:240px; text-align: left">
            <img width="30" style="padding-right:10px" src="/assets/facebook-logo.png" />Use My Facebook Account
          </button>
          <div style='font-weight: bold; margin-top: 20px'>
            Federation options:
          </div>
          <div style='margin-top: 10px; margin-left: 1em'>
            <mat-radio-group
              [(ngModel)]='usePopUp'
            >
              <mat-radio-button
                style='margin-top: 10px; margin-bottom: 10px'
                [value]="true"
              >Use Popup</mat-radio-button>
              <div>
                Faster, but does not work well with some browsers.
              </div>
              <br/>
              <mat-radio-button
                style='margin-top: 10px; margin-bottom: 10px'
                [value]="false"
              >Avoid Popup</mat-radio-button>
            </mat-radio-group>
            <div>
              Runs into fewer problems, but is slower.
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card fxFlex="0 1 18em" fxFlex.xs='100' fxHide fxShow.gt-sm [fxFlexOrder]='localFlexOrder'>
        <mat-card-header class="md-primary md-hue-1">
          <b>Local identity (email+password)</b>
        </mat-card-header>
        <mat-card-content>
          <auth-mail #mailLogin
            [useMail]='true'
            [usePwd]='true'
            buttonText='Use My Email'
            (go)='subSignin($event)'
          >
          </auth-mail>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-tab>


  <mat-tab label="Password" *ngIf='mqxssm$ | async'>
    <mat-card fxFlex="0 1 18em" fxFlex.xs='100'>
      <mat-card-header class="md-primary md-hue-1">
        <b>Local identity (email+password)</b>
      </mat-card-header>
      <mat-card-content>
        <auth-mail #mailLogin2
          [useMail]='true'
          [usePwd]='true'
          buttonText='Use My Email'
          (go)='subSignin($event)'
        >
        </auth-mail>
      </mat-card-content>
    </mat-card>
  </mat-tab>

  <mat-tab label="Reset">
    <div fxLayout="row wrap">
      <mat-card fxFlex="0 1 18em" fxFlex.xs='100'>
        <mat-card-header class="md-primary md-hue-1">
          <b>Reset Password</b>
        </mat-card-header>
        <mat-card-content>
          <auth-mail
            [useMail]='true'
            [usePwd]='false'
            buttonText='Reset Password'
            buttonClass='md-accent'
            (go)='subReset($event)'
          >
            <p>An email will be sent to your email address with a link back to the domain to reset password</p>
            <p style='text-decoration: underline' class='my-text-primary'>
              <span *ngIf='isProd; else devurl'>
                http://bank2.alexfung.info/
              </span>
              <ng-template #devurl>http://banktest.alexfung.info/</ng-template>
            </p>
            <p>Search for the mail under your Junk folder because systems often treat these mails as spam</p>
          </auth-mail>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-tab>
  <mat-tab label="Sign Up">
    <div fxLayout="row wrap">
      <mat-card fxFlex="0 1 18em" fxFlex.xs='100'>
        <mat-card-header class="md-primary md-hue-1">
          <b>Sign Up</b>
        </mat-card-header>
        <mat-card-content>
          <auth-mail
            [useName]='true'
            [useMail]='true'
            [usePwd]='true'
            buttonText='Create Account'
            buttonClass='md-primary'
            (go)='subSignup($event)'
          >
          Note: Name is only used for me to address you.
          It is not used in logging in.
          </auth-mail>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-tab>
</mat-tab-group>


