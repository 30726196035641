import { Injectable } from '@angular/core';
import { ParamMap, Params, ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';

@Injectable()
export class GuardService {

  constructor() {}

  /**
   * pm2p
   *
   * @param pm ParamMap or QueryParamMap from router
   * @returns the router private member Params rebuilt
   * I need to get the route.queryParams to JSON.stringify it
   * so that it can be pushed and later restored
   * and passed to this.router.navigate(, {queryParams: {}})
   * while the route.queryParams is exactly useful for router.navigate
   * the object is a private under route.queryParamMap
   * so need to create it myself.
   */

  public pm2p(pm: ParamMap): Params {
    const qp: Params = {};
    pm.keys.forEach(k => {
      qp[k] = pm.get(k);
    });
    return qp;
  }

  public r2p(route: ActivatedRouteSnapshot): Params {
    return this.pm2p(route.queryParamMap);
  }

  /**
   * walk the tree of ARS and extract the full URL
   *
   * @param ars ActivatedRouteSnapshot from router guards
   */
  public r2url(ars: ActivatedRouteSnapshot): string {
    let r = ars;
    let url = '';
    while (true) {
      if (r.url.length) url = '/' + r.url.join('/') + url;
      if (r.parent === null) break;
      r = r.parent;
    }
    return url;
  }

  /**
   * Not Yet Authenticated: route to /login
   */
  public notYetAuth(router: Router, route: ActivatedRouteSnapshot, url = '/login'): UrlTree {
    return router.createUrlTree([url], { queryParams: {
      returnUrl: this.r2url(route),
      returnQP: JSON.stringify(this.r2p(route))
    }});
  }

}
