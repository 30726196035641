<form #f="ngForm" novalidate>
  <div [style.height.em]="contentHeight" style="display: flex; flex-direction:column">
    <ng-content></ng-content>
    <mat-form-field *ngIf="useName">
      <input matInput ngModel name="name" placeholder="Name" #name="ngModel" required pattern="[a-zA-Z][a-zA-Z0-9 ]*" size="40">
      <mat-icon matPrefix>person</mat-icon>
      <div *ngIf="submitted && name.invalid && name.dirty" class="md-warn" style="font-size: small; margin-top:10px">
        Name can only contain alphabets and spaces
      </div>
      <br/>
    </mat-form-field>
    <mat-form-field *ngIf="useMail">
      <input matInput ngModel name="mail" type='email' email placeholder="email" #mail="ngModel" [(ngModel)]="pmail" required size="40">
      <mat-icon matPrefix>email</mat-icon>
      <div *ngIf="submitted && mail.invalid && mail.dirty" class="md-warn" style="font-size: small; margin-top:10px">
        email required
      </div>
      <br/>
    </mat-form-field>
    <mat-form-field *ngIf="usePwd">
      <input matInput ngModel name="pwd" [type]="pwdType" placeholder="password" #pwd='ngModel' required [minlength]="minPwd" size='40'>
      <mat-icon matPrefix>lock</mat-icon>
      <mat-icon matSuffix (click)="clickVisibility()" style="cursor:pointer">{{pwdIcon}}</mat-icon>
      <div *ngIf="submitted && pwd.invalid && pwd.dirty" class="md-warn" style="font-size: small; margin-top:10px">
        Minimum password length is 6
      </div>
      <br/>
    </mat-form-field>
    <div style="flex: 1 0 auto"></div>
  </div>
  <div style="display: flex">
    <span style="flex: 1 0 auto"></span>
    <button type='button'
      *ngIf="buttonText"
      mat-raised-button
      [color]='buttonClass.substring(3)'
      [ngClass]="buttonClass"
      (click)="onClick(f)"
    >{{buttonText}}</button>
  </div>
</form>
