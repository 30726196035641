import { Component, OnInit, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
// import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Database, ref, objectVal } from '@angular/fire/database';

import { Subscription, Subject } from 'rxjs';

import { environment } from 'environments/environment';
import * as uatVer from './shared/version-uat';
import * as prodVer from './shared/version-prod';
import { AuthService } from 'app/core/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public readonly isProduction = environment.production;
  public readonly versions = environment.production ? prodVer.versions : uatVer.versions;
  private _sub!: Subscription;
  public needRefresh = false;

  public dbDate = '';
  public myDate = '';

  public isOnline$ = new Subject<boolean>();

  constructor(
    private _afDB: Database
    , public authService: AuthService
    , private _updates: SwUpdate
  ) { }

  ngOnInit() {
    if (location.hostname !== 'localhost') {
      // this._sub = this._afDB.object<string>('/built/date').valueChanges().subscribe((dbDate) => {
      this._sub = objectVal<string>(ref(this._afDB, '/built/date')).subscribe((dbDate) => {
        if (!dbDate) {
          console.log('built database record not found!');
        } else {
          // console.log('db Date is ', this.dbDate = dbDate);
          // console.log('my built is ', this.myDate = versions.date);
          console.log('db Date is ', dbDate);
          console.log('my built is ', this.versions.date);
          if (dbDate > this.versions.date) {
            console.log('db is newer: ', dbDate, this.versions.date);
            // this.needRefresh = true;
          } else if (dbDate < this.versions.date || !dbDate) {
            console.log('db is older: ', dbDate, this.versions.date);
          }
        }
      });
    }
    // angular v13 syntax, SwUpdate.available/activated are deprecated
    if (this._updates.isEnabled) {
      this._updates.versionUpdates.subscribe(evt => {
        if (evt.type === 'VERSION_READY') {
          console.log('current version is', evt.currentVersion.appData);
          console.log('available version is', evt.latestVersion.appData);
          this.myDate = JSON.stringify(evt.currentVersion.appData, null, 2);
          this.dbDate = JSON.stringify(evt.latestVersion.appData, null, 2);
          this.needRefresh = true;
        }
        if (evt.type === 'VERSION_DETECTED') {
          console.log('New version detected', evt.version.appData);
        }
      });
    }
    // this._updates.available.subscribe(event => {
    //   console.log('current version is', event.current);
    //   console.log('available version is', event.available);
    //   this.myDate = (event.current.appData) as string;
    //   this.dbDate = (event.available.appData) as string;
    //   this.needRefresh = true;
    // });
    // this._updates.activated.subscribe(event => {
    //   console.log('old version was', event.previous);
    //   console.log('new version is', event.current);
    // });
    // this._afDB.object<boolean>('.info/connected').valueChanges().subscribe(this.isOnline$);
    objectVal<boolean>(ref(this._afDB, '.info/connected')).subscribe(this.isOnline$);
  }

  public refreshReload() {
    location.reload();
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }
}
