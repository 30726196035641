import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

// import 'firebase/database'; // do I really need this?
// import { AngularFireModule
//   , FIREBASE_OPTIONS // angularfire@6 breaking change
//   // , FirebaseOptionsToken
//   // angularfire2@500rc8
//   // , FirebaseAppNameToken
//   // , FirebaseAppConfigToken
//   // angularfire2@500rc9
//   // , FirebaseNameOrConfigToken
// } from '@angular/fire/compat';
// import { AngularFireAuthModule } from '@angular/fire/compat/auth';
// import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { getDatabase, provideDatabase } from '@angular/fire/database';

import { MyMaterialModule } from 'app/shared/material.module';
// import 'hammerjs';
import { FlexLayoutModule } from '@angular/flex-layout';

import { environment } from 'environments/environment';

// import { CustomBreakPointsModule } from './core/custom-breakpoints.module';
import { MyBreakPointsModule } from './core/my-break-points.module';

import { CoreModule } from './core/core.module';
import { HomeModule } from './home/home.module';
import { AuthModule } from './auth/auth.module';
import { AppRoutingModule } from './app-routing/app-routing.module';

import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule
    , BrowserAnimationsModule
    , FormsModule
    // , FirebaseModule
    // , AngularFireModule
    // , AngularFireDatabaseModule
    // , AngularFireAuthModule
    , provideFirebaseApp(() => initializeApp(environment.firebase))
    , provideAuth(() => {
      const auth = getAuth();
      return auth;
    })
    , provideDatabase(() => {
      const database = getDatabase();
      return database;
    })

    , MyMaterialModule
    // , MaterialModule
    , FlexLayoutModule
    , MyBreakPointsModule
    // , CustomBreakPointsModule
    , CoreModule
    , HomeModule
    , AuthModule
    , AppRoutingModule
    , ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    // { provide: FIREBASE_OPTIONS, useValue: environment.firebase }
    // angularfire2@500rc8
    // , { provide: FirebaseAppNameToken, useValue: 'bank2' }
    // , { provide: FirebaseAppConfigToken, useValue: undefined }
    // angularfire2@500rc9
    // , { provide: FirebaseNameOrConfigToken, useValue: 'bank2' }
  ],
  bootstrap: [AppComponent ],
})
export class AppModule {
  constructor() {
    // 600b16
    FlexLayoutModule.withConfig({useColumnBasisZero: false});
  }
}
