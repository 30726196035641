<div style='width: 100%; width: 100vw; display: flex; justify-content: center'>
  <div style='width: 100%; max-width: 1280px; padding-bottom: 20px' class='mat-app-background'>
    <mat-toolbar class="md-primary"  >
      <span><h1>
        {{isProduction ? 'Bank2' : 'Bank2 Test'}}
        <span *ngIf='(isOnline$ | async) === false' class='md-primary md-hue-1'>Offline</span>
      </h1></span>
      <span style="flex: auto"></span>
      <ng-container *ngIf='authService.user$ | async; let u'>
        <span style="padding-right: 20px">{{u.name}}</span>
        <mat-icon *ngIf='u?.uid' class="mdicon-right" style="cursor:pointer" (click)="authService.logout()">exit_to_app</mat-icon>
      </ng-container>
    </mat-toolbar>
    <div *ngIf='needRefresh'>
      <div class='my-text-accent'>
        An updated version has been published.
        <br/>
        Please use the Refresh button below to reload.
        <br/>
        Running:  {{ myDate }}<br/>
        Released: {{ dbDate }}<br/>
      </div>
      <br/>
      <button
        (click)='refreshReload()'
        mat-raised-button
        color="primary"
      >Refresh</button>
      <br/><br/>
      <hr/>
    </div>

    <nav>
      <ng-container *ngIf='authService.user$ | async as u'>
        <button mat-button routerLink="/home" routerLinkActive="active" class="mat-button" [disabled]="!u.mid">Home</button>
        <button mat-button routerLink="/stmt/" routerLinkActive="active" class="mat-button" [disabled]="!u.mid">Stmt</button>
        <button mat-button routerLink="/survey" routerLinkActive="active" class="mat-button" [disabled]="!u.mid">Survey</button>
        <button mat-button routerLink="/account" routerLinkActive="active" class="mat-button" [disabled]="!u.uid">Account</button>
      </ng-container>
      <button mat-button routerLink="/login" routerLinkActive="active" class="mat-button">Login</button>
      <ng-container *ngIf='authService.user$ | async as u'>
        <button mat-button routerLink="/today" *ngIf='u.isAdmin' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="mat-button">RSVP</button>
        <button mat-button routerLink="/backend" *ngIf='u.isAdmin' routerLinkActive="active" class="mat-button">MDB</button>
        <button mat-button routerLink="/server" *ngIf="u.isServer" routerLinkActive="active" class="mat-button">Server</button>
      </ng-container>
    </nav>
    <div style='margin-left: 2px'>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

