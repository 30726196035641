import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MyMaterialModule } from './material.module';

// import 'hammerjs';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from 'app/core/custom-date-adapter';

// import { AngularFireAuthModule } from '@angular/fire/compat/auth';
// import { AngularFireDatabaseModule } from '@angular/fire/compat/database';

import { MdCalendarModule } from './calendar/calendar';
import { MyDatepickerComponent } from './my-datepicker/my-datepicker.component';

@NgModule({
  imports: [
    CommonModule
    , FormsModule
    , MatDatepickerModule
    , MatFormFieldModule
    , MatInputModule
    // , MyMaterialModule
    // , MaterialModule
  ],
  declarations: [
    MyDatepickerComponent
   ],
   providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter }
  ],
  exports: [
    CommonModule
    , FormsModule
    , RouterModule
    , MyMaterialModule
    // , MaterialModule
    , FlexLayoutModule
    , MdCalendarModule
    , MyDatepickerComponent
    // , AngularFireAuthModule
    // , AngularFireDatabaseModule
  ]

})
export class SharedModule { }
