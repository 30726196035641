import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';

// MdButtonToggleModule,
import { MatCardModule } from '@angular/material/card';
// MdChipsModule,
import { MatCheckboxModule } from '@angular/material/checkbox';
// MdDialogModule,
import { MatFormFieldModule } from '@angular/material/form-field';
// MdGridListModule,
// new in beta.10
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';

import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
// MdSidenavModule,
// MdSliderModule,
// MdSlideToggleModule,
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule
// MdTooltipModule,
// PortalModule,
// RtlModule,
// StyleModule,
 } from '@angular/material/toolbar';
// breaking change @angular/material@2.0.0-beta.11 observers removed from material
// import { ObserversModule } from '@angular/cdk/observers';
// breaking change @angular/material@2.0.0-beta.10
// modules moved to cdk:
// import { A11yModule } from '@angular/cdk/a11y';
// import { OverlayModule } from '@angular/cdk/overlay';
// import { PortalModule } from '@angular/cdk/portal';
// import { PlatformModule } from '@angular/cdk/platform';

@NgModule({ exports: [
  MatAutocompleteModule,
  MatButtonModule,
  // MdButtonToggleModule,
  MatCardModule,
  // MdChipsModule,
  MatCheckboxModule,
  // MdDialogModule,
  // MdGridListModule,
  MatFormFieldModule, // new in beta.10
  MatIconModule,
  MatInputModule,
  MatNativeDateModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  // MdSidenavModule,
  // MdSliderModule,
  // MdSlideToggleModule,
  MatSnackBarModule,
  MatTabsModule,
  MatToolbarModule
  // MdTooltipModule,
  // OverlayModule,
  // RtlModule,
  // StyleModule,
]})
export class MyMaterialModule { }
