<mat-form-field style='width: 12em'>
  <mat-datepicker
    [touchUi]="touchUI"
    #picker
    [startAt]='dDate'
    (selectedChanged)='dDateSetUI($any($event))'
  ></mat-datepicker>
  <i
    (click)='dateAdd(-1)'
    style='cursor: pointer'
    class="material-icons"
    [ngClass]='{"disabled": isDisabled}'
    matPrefix
  >arrow_back</i>
  <input
    matInput
    name='matSelectedDate'
    size='10'
    [disabled]='isDisabled'
    [ngModel]='dDate'
    [placeholder]='placeholder'
    #in
    (dateChange)='inDateSetUI(picker, $event)'
    (blur)='propagateTouch()'
    [matDatepicker]="picker"
  >
  <span matSuffix>
    <i
      (click)='today()'
      style='cursor: pointer'
      class="material-icons"
      [ngClass]='{"disabled": isDisabled}'
    >restore</i>
    <i
      (click)='dateAdd(1)'
      style='cursor: pointer'
      class="material-icons"
      [ngClass]='{"disabled": isDisabled}'
    >arrow_forward</i>
    <mat-datepicker-toggle
      [disabled]='isDisabled'
      style='font-size: large'
      [for]="$any(picker)">
    </mat-datepicker-toggle>
  </span>
</mat-form-field>

