<div class="mat-calendar-header">
    <button (click)="prevPage()" mat-mini-fab class='md-primary' [disabled]='prevDisable'>&#8592;</button>
    <button (click)="previousViewMode()" mat-button class="mat-calendar-header-title">
        {{ viewHeader }}
    </button>
    <button (click)="nextPage()" mat-mini-fab class='md-primary' [disabled]='nextDisable'>&#8594;</button>
</div>
<div class="mat-calendar-row" *ngIf="viewMode === 'month'">
    <div *ngFor="let weekDay of weekDays" style='font-size:smaller'>
        {{ weekDay }}
    </div>
</div>
<div class="mat-calendar-row" *ngFor="let row of calendarRows">
    <button
            mat-raised-button
            [class.mat-calendar-partial-selection]="entry.selected === 'partial'"
            [color]="(entry.selected === 'none' || entry.selected === 'invalid') ? null : 'primary'"
            [disabled]="entry.selected === 'invalid'"
           (click)="selectEntry(entry)"
            *ngFor="let entry of row">
        {{ entry.toString() }}
    </button>
</div>
            <!--
            [style.backgroundColor]="entry.selected === 'invalid' ? 'rgba(0,0,0,0.117647)' : null"
            [style.color]="entry.selected === 'invalid' ? 'rgba(0,0,0,0.38)' : null"
            -->
