import { Injectable } from '@angular/core';
// import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Auth, authState } from '@angular/fire/auth';

// import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Database, ref, push, set } from '@angular/fire/database';

//import { User } from '@firebase/auth-types';
// import { User } from '@angular/fire/auth';

import { take } from 'rxjs/operators';

import { format } from 'date-fns';

/*
 * requests are queued into /request
 * and processed by server/request.js
 */

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(
    private _afAuth: Auth
    , private _afDB: Database
  ) { }

  public request(action: string, payload: unknown): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      authState(this._afAuth).pipe(take(1)).subscribe(user => {
        // this._afDB.database.ref(`/request`).push().set({
        // ref(this._afDB, `/request`).push().set({
        // push(ref(this._afDB, `/request`)).set({
        if (user) set(push(ref(this._afDB, `/request`)), {
          user: user.uid,
          action,
          provider: user.providerData[0].providerId,
          date: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss.SSS 'UTC'xx`),
          mail: user.email,
          verified: user.emailVerified,
          payload
        }).then(() => {
          resolve(true);
        });
      });
    });
  }
}
