import { NgModule } from '@angular/core';
import { BreakPoint, BREAKPOINT } from '@angular/flex-layout';

const arrAlias = ['xs', 'sm', 'md', 'lg', 'xl'] as const;
export type Alias = typeof arrAlias[number];

@NgModule({
  providers: [
    {
      provide: BREAKPOINT,
      useFactory: customizeBreakPoints,
      multi: true
    }
  ]
})
export class MyBreakPointsModule { }

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function customizeBreakPoints(): BreakPoint[] {
  const smMin = 384;
  const mdMin = 768;
  const lgMin = 1280;
  const xlMin = 1920;
  const arrBreakpoint: ReadonlyArray<number> = [0, smMin, mdMin, lgMin, xlMin, 5001];
  // arrAlias[i] is arrBreakpoint[i] <= width < arrBreakpoint[i+1]
  // const arrAlias: ReadonlyArray<string> = ['xs', 'sm', 'md', 'lg', 'xl'];
  const customBreakPoints: BreakPoint[] = new Array();

  arrAlias.forEach((alias: string, idx: number) => {
    const mqMax = `and (max-width: ${arrBreakpoint[idx + 1] - 0.1}px)`;
    const mqMin = `and (min-width: ${arrBreakpoint[idx]}px)`;
    const aliasSuffix = alias.substring(0, 1).toUpperCase() + alias.substring(1);
    // turn first char to upper case,
    // as required in breakpoint suffix
    // too bad ES dones't have a toTitleCase()

    if (idx) {
      // lt- if not fist alias
      customBreakPoints.push({
        alias: `lt-${alias}`,
        mediaQuery: `screen and (max-width: ${arrBreakpoint[idx] - 0.1}px)`,
        overlapping: true,
        suffix: `Lt${aliasSuffix}`
      });
      // alias itself, not overlapping
      customBreakPoints.push({
        alias,
        mediaQuery: `screen ${mqMin} ${mqMax}`,
        overlapping: false,
        suffix: aliasSuffix
      });
    } else {
      // no lt- for fist alias
      // no min-width for xs, <-- does not work
      // not overlapping
      // bug in beta-24:
      //   if xs does not have "and (min-width: 0px)"
      //   changes[] does not contain xs, only lt-sm, lt-md etc
      customBreakPoints.push({
        alias,
        // mediaQuery: `screen ${mqMax}`,
        mediaQuery: `screen ${mqMin} ${mqMax}`,
        overlapping: false,
        suffix: aliasSuffix
      });
    }
    if (idx < arrAlias.length - 1) {
      // gt- if not last alias
      customBreakPoints.push({
        alias: `gt-${alias}`,
        mediaQuery: `screen and (min-width: ${arrBreakpoint[idx + 1]}px)`,
        overlapping: true,
        suffix: `Gt${aliasSuffix}`
      });
    }
  });
  // console.log(JSON.stringify(customBreakPoints, null, 2));
  return customBreakPoints;
}
