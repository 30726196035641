/*
 * This presentation componenet displays a simple form with
 * name/mail/pwd and a button
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

import { AuthService } from 'app/core/auth.service';

type TpwdType = 'password' | 'text';
type TpwdIcon = 'visibility_off' | 'visibility';
@Component({
  selector: 'auth-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.css']
})
export class MailComponent {
  @Input() useName = false;
  @Input() useMail = true;
  @Input() usePwd = true;
  @Input() minPwd = 6;
  @Input() contentHeight = 14;
  @Input() buttonText = '';
  @Input() buttonClass = 'md-primary';
  @Input() msg = '';
  @Output() go: EventEmitter<NgForm> = new EventEmitter();

  private readonly pwdTypeHide: TpwdType = 'password' as const;
  private readonly pwdTypeShow: TpwdType = 'text' as const;
  public pwdType: TpwdType = this.pwdTypeHide;

  private readonly pwdIconHide: TpwdIcon = 'visibility' as const;
  private readonly pwdIconShow: TpwdIcon = 'visibility_off' as const;
  public pwdIcon: TpwdIcon = this.pwdIconHide;

  public submitted = false;
  // private _myform: NgForm;
  public pmail = '';
  // linked to mail field
  // potentially @Input()

  constructor(
    public authService: AuthService
  ) { }

  /*
   * show/hide the password field
   */
  clickVisibility() {
    if (this.pwdIcon === this.pwdIconShow) {
      this.pwdIcon = this.pwdIconHide;
      this.pwdType = this.pwdTypeHide;
    } else {
      this.pwdIcon = this.pwdIconShow;
      this.pwdType = this.pwdTypeShow;
    }
  }

  public onClick(f: NgForm) {
    // this._myform = f;
    if (f.form.valid) {
      this.submitted = false;
      this.msg = '';
      // this.go.emit(<INameMailPwd>f.value);
      this.go.emit(f);
    } else this.submitted = true;
  }
}
