<div>Build: {{ versions.date }}</div>

<div *ngIf='bankuser$ | async as bu' style='margin-top: 10px'>
  <div *ngIf='bu.balancePayable < 0'>
    <div *ngIf='bu.duedate && bu.duedate > this.today'>
      The amount ${{-bu.balancePayable}} will be due on {{bu.duedate}}.
    </div>
    <div *ngIf='bu.duedate && bu.duedate === this.today'>
      The amount ${{-bu.balancePayable}} is due today.
    </div>
    <div *ngIf='bu.duedate && bu.duedate < this.today'>
      The amount ${{-bu.balancePayable}} is overdue since {{bu.duedate}}.
    </div>
    <div>
      Please refer to the payment instructions in
      <span style='text-decoration: underline; cursor: pointer' (click)='goStmt(bu.latest)'>the statement</span>.
    </div>
  </div>
  <br/>
  <div *ngIf='survey$ | async'>
    When should we eat?
    Please <span style='text-decoration: underline; cursor: pointer' (click)='goSurvey()'>respond to the survey</span>.
  </div>
  <div *ngIf='authService.user.isAdmin'>
    Users with outstanding balance:
    <div
      *ngFor='let u of osusers$ | async'
      style='margin-top: 5px; margin-left: 1em; text-decoration: underline; cursor: pointer'
      (click)='goStmt(u.latest, u.aid)'
    >
      {{u.addr}} ${{-u.balancePayable}}
    </div>
  </div>

</div>
