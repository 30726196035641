import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Title } from '@angular/platform-browser';

// import { AuthService } from './auth.service';
// import { RequestService } from './request.service';
// import { MediaQueryService } from './media-query.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    Title
    // , AuthService
    // , RequestService
    // , MediaQueryService
  ]

})
export class CoreModule { }
