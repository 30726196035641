<mat-toolbar class="mat-grey-appbar">
  <span>Account Management</span>
</mat-toolbar>

<mat-tab-group [selectedIndex]='isLocal?0:1'  *ngIf='authService.user$ | async as user'>
  <mat-tab label="Local" [disabled]='!isLocal'>
    <p>The tools here help you to manage your local account (email+password).</p>
    <div fxLayout="row wrap">
      <mat-card fxFlex="18em" fxFlex.xs="100" *ngIf="!user.verified">
        <mat-card-header class="md-primary md-hue-1">
          <b>Verify my email</b>
        </mat-card-header>
        <mat-card-content>
          <auth-mail
            [useName]='false'
            [useMail]='false'
            [usePwd]='false'
            [contentHeight]='contentHeight'
            buttonText='Verify'
            buttonClass='md-primary'
            (go)='subVerifyMail()'
          >
            <p>An email will be sent to</p>
            <p style='text-decoration: underline' class='my-text-primary'>{{user.email}}</p>
            <p>with a link back to</p>
            <p style='text-decoration: underline' class='my-text-primary'>
              <span *ngIf='isProd; else devurl'>
                http://bank2.alexfung.info/
              </span>
              <ng-template #devurl>http://banktest.alexfung.info/</ng-template>
            </p>
            <p>When you receive the mail, please click the link to confirm your address.</p>
            <p>Search for the mail under your Junk folder because systems often treat these mails as spam</p>
          </auth-mail>
        </mat-card-content>
      </mat-card>

      <mat-card fxFlex="18em" fxFlex.xs="100">
        <mat-card-header class="md-primary md-hue-1">
          <b>Change Name</b>
        </mat-card-header>
        <mat-card-content>
          <auth-mail
            [useName]='true'
            [useMail]='false'
            [usePwd]='false'
            [contentHeight]='contentHeight'
            buttonText='Change'
            buttonClass='md-primary'
            (go)="subChangeName($event)"
          >
            <p *ngIf='user.name === "no name"'>Set up your name</p>
            <div *ngIf='user.name !== "no name"'>
              <p>Change your name from</p>
              <p class='my-text-primary'>{{user.name}}</p>
              <p>to a new name.</p>
            </div>
          </auth-mail>
        </mat-card-content>
      </mat-card>

      <mat-card fxFlex="18em" fxFlex.xs="100">
        <mat-card-header class="md-primary md-hue-1">
          <b>Change Password</b>
        </mat-card-header>
        <mat-card-content>
          <auth-mail
            [useName]='false'
            [useMail]='false'
            [usePwd]='true'
            [contentHeight]='contentHeight'
            buttonText='Change'
            buttonClass='md-primary'
            (go)='subChangePwd($event)'
          >
            <p>Change the password for your local identity</p>
          </auth-mail>
        </mat-card-content>
      </mat-card>

      <mat-card fxFlex="18em" fxFlex.xs="100">
        <mat-card-header class="md-primary md-hue-1">
          <b>Change Mail</b>
        </mat-card-header>
        <mat-card-content>
          <auth-mail
            [useName]='false'
            [useMail]='true'
            [usePwd]='false'
            [contentHeight]='contentHeight'
            buttonText='Change'
            buttonClass='md-primary'
            (go)='subChangeMail($event)'
          >
            <p>Change the email address of your local identity from</p>
            <p style='text-decoration: underline' class='my-text-primary'>{{user.email}}</p>
            <p>to a new address.</p>
          </auth-mail>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-tab>
  <mat-tab label="Bank">
    <p *ngIf='user.mid; else needConnect'>
      You have already connected to your bank information.
      No further maintenance is needed.
    </p>
    <ng-template #needConnect>
      <p>
        After you have the web account, you need to connect to your bank information.
        You can use either method below.
        It should be connected the next time you log in.
      </p>
      <div fxLayout="row wrap">
        <mat-card fxFlex="18em" fxFlex.xs="100">
          <mat-card-header class="md-primary md-hue-1">
            <b>Migration from old system</b>
          </mat-card-header>
          <mat-card-content>
            <auth-mail
              [useName]='true'
              [useMail]='false'
              [usePwd]='true'
              [minPwd]='+1'
              buttonText='Connect'
              (go)='subPHP($event)'
            >
            If you have an web account on the previous bank system,
            you can enter your username and password here
            to connect your new account to your bank information.
            </auth-mail>
          </mat-card-content>
        </mat-card>
        <mat-card fxFlex="18em" fxFlex.xs="100">
          <mat-card-header class="md-primary md-hue-1">
            <b>Manual connection</b>
          </mat-card-header>
          <mat-card-content>
            <auth-mail
              [useName]='false'
              [useMail]='false'
              [usePwd]='false'
              buttonText=''
            >
            <p>
              A second method.
              Send the following ID to me
              and I'll connect you manually.:</p>
            <br>
            <p>{{user.uid}}</p>
            </auth-mail>
          </mat-card-content>
        </mat-card>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>


<div></div>

