import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { lightFormat } from 'date-fns';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  useUtcForDisplay = true;

  parse(aDate: unknown): Date | null {
    // console.log('parsing ', sDate);
    const today = new Date();
    const thisDay = today.getDate();
    const thisMonth = today.getMonth(); // month number starts from zero
    const thisYear = today.getFullYear();

    if (typeof aDate === 'string') {
      let sDate: string = aDate;
      // it seems that the current ts can already infer aDate is a string inside this if
      // making the string variable unnecessary
      let prev = true;
      if (sDate.startsWith('n')) {
        prev = false;
        sDate = sDate.substring(1);
      }
      const slashDate = sDate.split('/');
      // format 1:
      // d(/m(/y)?)?
      if (slashDate.length > 1 || /^\d\d?$/.test(sDate)) {
        const date = Number(slashDate[0]);
        const month = slashDate.length >= 2 ? Number(slashDate[1]) - 1 :
          prev ? date > thisDay ? thisMonth - 1 : thisMonth
          : date < thisDay ? thisMonth + 1 : thisMonth;
        let year: number;
        // console.log(date, month, year);
        if (slashDate.length >= 3) {
          year = Number(slashDate[2]);
          if (year < 80) year += 2000;
        } else {
          if (prev && (month > thisMonth || month === thisMonth && date > thisDay))
            year = thisYear - 1;
          else if (!prev && (month < thisMonth || month === thisMonth && date < thisDay))
            year = thisYear + 1;
          else
            year = thisYear;
        }
        // console.log('returning ', new Date(year, month, date));
        return new Date(year, month, date);
      }
      // format 2
      // yyyy-mm-dd or mm-dd (current year)
      // any string with 1 or >2 hyphen will return giberish
      if (sDate) {
        const str = sDate.split('-');
        // if use switch() instead of if-else
        // branches belong to the same scope
        // and the pair of const will result in error
        if (str.length === 3) {
          // yyyy-mm-dd
          const year = Number(str[0]);
          const month = Number(str[1]) - 1;
          // console.log(3, str[2], str[2].split('T'));
          const date = Number((str[2].split('T'))[0]);
          // console.log('returning ', new Date(year, month, date));
          // in case caller provided something like 2016-12-10T00:00a:00.000Z
          return new Date(year, month, date);
        } else if (str.length === 2) {
          // mm-dd, imply current year
          const month = Number(str[0]) - 1;
          const date = Number((str[1].split('T'))[0]);
          // console.log(2, str[1], str[1].split('T'));
          // console.log('returning ', new Date(year, month, date));
          // in case caller provided something like 2016-12-10T00:00a:00.000Z
          return new Date(thisYear, month, date);
        }
      }
      // else falls through to base class
    }
    // console.log('returning super ', super.parse(sDate));
    return super.parse(aDate);
    // const timestamp = typeof value === 'number' ? value : Date.parse(value);
    // return isNaN(timestamp) ? null : new Date(timestamp);
  }

  format(date: Date, displayFormat: {[key: string]: string}): string {
    // displayFormat ===
    //   {year: 'numeric', month: 'numeric', day: 'numeric'} is always false
    // cannot compare objects like this
    // must test the fields separately
    if (displayFormat.year === 'numeric' && displayFormat.month === 'numeric' && displayFormat.day === 'numeric') {
      // console.log('formatting nununu: ', date);
      return lightFormat(date, 'yyyy-MM-dd');
    }
    return super.format(date, displayFormat);
  }
}
