import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { AuthGuardService } from './auth-guard.service';
import { MidGuardService } from './mid-guard.service';
import { AdminGuardService } from './admin-guard.service';
import { ServerGuardService } from './server-guard.service';

import { GuardService } from './guard.service';

const routes: Routes = [
  {
    path: ''
    , redirectTo: '/home'
    , pathMatch: 'full'
  }
  , {
    path: 'stmt'
    , loadChildren: () => import('app/stmt/stmt.module').then(m => m.StmtModule)
  }
  , {
    path: 'survey'
    , loadChildren: () => import('app/survey/survey.module').then(m => m.SurveyModule)
  }
  , {
    path: 'today'
    , loadChildren: () => import('app/event/event.module').then(m => m.EventModule)
    , canLoad: [AdminGuardService]
  }
  , {
    path: 'server'
    , loadChildren: () => import('app/server/server.module').then(m => m.ServerModule)
    , canLoad: [ServerGuardService]
  }
  , {
    path: 'backend'
    , loadChildren: () => import('app/backend/backend.module').then(m => m.BackendModule)
    , canLoad: [AdminGuardService]
  }
  , {
    path: '**'
    , redirectTo: '/home'
  }
];

@NgModule({
  imports: [
    CommonModule
    , RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ]
  , exports: [ RouterModule ]
  , providers: [
    AuthGuardService
    , MidGuardService
    , AdminGuardService
    , ServerGuardService, GuardService
    ]
})
export class AppRoutingModule { }
