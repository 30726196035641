import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

// import { firebase } from '@firebase/app';
// avoid @firebase, supposedly internal
// import firebase from 'firebase/compat/app';
// import { FirebaseError } from '@firebase/util';
// import { AngularFireAuth } from '@angular/fire/compat/auth';
// import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Database, ref, objectVal } from '@angular/fire/database';
import { FirebaseError } from '@angular/fire/app';


import {
  Auth, EmailAuthProvider,
  updateProfile, updatePassword, updateEmail, sendEmailVerification
} from '@angular/fire/auth';

import { Subscription, ReplaySubject, of } from 'rxjs';
import { first, timeout, catchError, takeUntil } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { INameMailPwd } from '../mail/mail.model';
import { AuthService } from 'app/core/auth.service';
import { RequestService } from 'app/core/request.service';

// make K required instead of optional
// no idea why Omit<T, never> is needed, looks the same without it
type RequiredByKeys<T, K = keyof T> = Omit<T & Required<Pick<T, K & keyof T>>, never>;

@Component({
  selector: 'auth-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit, OnDestroy {
  public isLocal = false;
  public readonly contentHeight = 14;
  private returnUrl = '';
  private _userSub: Subscription | null = null;
  public readonly isProd = environment.production;

  constructor(
    public snackBar: MatSnackBar
    , public authService: AuthService
    , private router: Router
    , private route: ActivatedRoute
//    , private php: CheckphpService
    , private rq: RequestService
    , private titleService: Title
    , private _afAuth: Auth
    , private _afDB: Database
    , private _ngZone: NgZone
  ) { }

  ngOnInit() {
    this.titleService.setTitle(`Bank: Account Maintenance`);
    this.isLocal = (this.authService.user.provider === EmailAuthProvider.PROVIDER_ID);
    const QPM = this.route.snapshot.queryParamMap;
    if (!!(this.returnUrl = QPM.get('returnUrl') ?? '')) {
      // console.log(`returnUrl=${this.returnUrl}, QP=`, JSON.parse(QPM.get('returnQP')));
      if (this.authService.user.mid) this.returnNow();
      this._userSub = this.authService.user$.subscribe(
        user => { if (user.mid) this.returnNow(); }
      );
    }
  }

  private returnNow() {
    if (this.returnUrl) {
      const QPM = this.route.snapshot.queryParamMap;
      // console.log(`account going back to ${this.returnUrl} with QP=`, JSON.parse(QPM.get('returnQP')));
      this._ngZone.run(() => this.router.navigate(
        [this.returnUrl]
        , {queryParams: JSON.parse(QPM.get('returnQP') ?? '')}
      ));
    }
  }

  subChangeName(f: NgForm) {
    if (this.isLocal) {
      const v = f.value as RequiredByKeys<INameMailPwd, 'name'>;
      const user = this._afAuth.currentUser;
      if (user) {
        updateProfile(user, {
          displayName: v.name,
          photoURL: user.photoURL
          // not passing photoURL will keep it unchanged,
          // but typings require both object properties to be present
          // passing null will delete the attribute
          // actually doesn't matter because no fucntion yet updates the photoURL
        }).then(() => {
          this.authService.nameChanged();
          this.snackBar.open(`Name changed to ${v.name} successfully`, undefined, { duration: 3000 });
        }, (error: FirebaseError) => {
          this.snackBar.open(`Name change failed: ${error.code}.`, 'OK');
          console.warn(error);
        });
      }
    } else this.snackBar.open(`Change email ignored for provider: ${this.authService.user.provider}.`, undefined, { duration: 3000 });
  }

  subChangePwd(f: NgForm) {
    if (this.isLocal) {
      const v = f.value as RequiredByKeys<INameMailPwd, 'pwd'>;
      if (this._afAuth.currentUser) {
        updatePassword(this._afAuth.currentUser, v.pwd).then(() => {
          this.snackBar.open(`Password updated.`, undefined, { duration: 3000 });
        }, (error: FirebaseError) => {
          if (error.code === 'auth/requires-recent-login') {
            this.snackBar.open(`Please do this again immediately after a login.`, 'OK');
          } else {
            this.snackBar.open(`Updated password failed: ${error.code}.`, 'OK');
            console.warn(error);
          }
        });
      }
    } else this.snackBar.open(`Change password ignored for provider: ${this.authService.user.provider}.`, undefined, { duration: 3000 });
  }

  subChangeMail(f: NgForm) {
    if (this.isLocal) {
      const v = f.value as RequiredByKeys<INameMailPwd, 'mail'>;
      if (this._afAuth.currentUser) {
        updateEmail(this._afAuth.currentUser, v.mail).then(() => {
          this.snackBar.open(`Address changed successfully. Logging you off. `, 'OK')
            .onAction().pipe(first()).subscribe(() => this.authService.logout());
        }, (error: FirebaseError) => {
          if (error.code === 'auth/requires-recent-login') {
            this.snackBar.open(`Please do this again immediately after a login.`, 'OK');
          } else {
            this.snackBar.open(`Address change failed: ${error.code}.`, 'OK');
            console.warn(error);
          }
        });
      }
    } else this.snackBar.open(`Change email ignored for provider: ${this.authService.user.provider}.`, 'OK');
  }

  subVerifyMail() {
    if (this.isLocal) {
      if (this._afAuth.currentUser) {
        sendEmailVerification(this._afAuth.currentUser).then(() => {
          this.snackBar.open(`Verification mail sent`, undefined, { duration: 3000 });
        }, (error: FirebaseError) => {
          this.snackBar.open(`Verification mail failed to send.`, 'OK');
          console.warn(error);
        });
      } else {
        this.snackBar.open(`User invalid.`, undefined, { duration: 3000 });
      }
    } else this.snackBar.open(`Verification not applicable to provider: ${this.authService.user.provider}.`, 'OK');
  }

  subPHP(f: NgForm) {
    /*
     * the original intension is the check with PHP before making the request
     * it does not work, because https site cannot post request to http
     */
    const v = f.value as RequiredByKeys<INameMailPwd, 'name' | 'pwd'>;
    const taking$ = new ReplaySubject<boolean>(1);
    this.rq.request('connectPwd', v).then(_ => {
      this.snackBar.open(`Connection requested.`, undefined, { duration: 3000 });
      // console.log(this.authService.user.uid);
      // this._afDB.object<number>(`/registry/${this.authService.user.uid}/mid`).valueChanges().pipe(
      objectVal<number>(ref(this._afDB, `/registry/${this.authService.user.uid}/mid`)).pipe(
        takeUntil(taking$),
        timeout(5000),
        catchError(() => of(-1))
      ).subscribe({
        next: aid => {
          switch (aid) {
            case null:
              break;
            case -1:
              this.snackBar.open(`Name/Password incorrect.`, undefined, { duration: 3000 });
              taking$.next(false);
              break;
            default:
              taking$.next(true);
              this.authService.reloadClaim();
          }
        },
        complete: () => {
          taking$.complete();
        }
      });
    });
  }

  ngOnDestroy() {
    if (this._userSub) this._userSub.unsubscribe();
  }
}
