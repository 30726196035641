import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
// import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Auth, authState } from '@angular/fire/auth';

import { take } from 'rxjs/operators';

import { GuardService } from './guard.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router
    , private _afAuth: Auth
    , private _gs: GuardService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return new Promise<boolean | UrlTree>((resolve) => {
      authState(this._afAuth).pipe(take(1)).subscribe(u => {
        // console.log('auth guard subscribe user ', u);
        if (u) resolve(true);
        else {
          resolve(this._gs.notYetAuth(this.router, route));
        }
      });
    });
  }
}
