import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable ,  Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

// import { firebase } from '@firebase/app';
// import { AuthProvider, User, UserCredential } from '@firebase/auth-types';
// import { FirebaseError } from '@firebase/util';

// import firebase from 'firebase/compat/app';
// import type { AuthProvider, UserCredential } from '@firebase/auth-types';
// import { AngularFireAuth } from '@angular/fire/compat/auth';

import {
  Auth, authState, UserCredential,
  AuthProvider, signInWithEmailAndPassword, GoogleAuthProvider, FacebookAuthProvider,
  signInWithPopup, signInWithRedirect, getRedirectResult, updateProfile,
  sendPasswordResetEmail, createUserWithEmailAndPassword
} from '@angular/fire/auth';
import { FirebaseError } from '@angular/fire/app';

import { environment } from 'environments/environment';
import { INameMailPwd } from '../mail/mail.model';
import { AuthService } from 'app/core/auth.service';
import { Alias } from 'app/core/my-break-points.module';
import { MediaQueryService } from 'app/core/media-query.service';
import { RequestService } from 'app/core/request.service';

// make K required instead of optional
// no idea why Omit<T, never> is needed, looks the same without it
type RequiredByKeys<T, K = keyof T> = Omit<T & Required<Pick<T, K & keyof T>>, never>;
@Component({
  selector: 'auth-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  public mqxssm$!: Observable<boolean>;
  public localFlexOrder = 3;
  public tabgroupInitial = 0;
  public readonly isProd = environment.production;

  public usePopUp = true;

  private returnUrl = '';
  private signingUp = false;

  private _initSub: Subscription | null = null;

  constructor(
    public authService: AuthService
    , private _afAuth: Auth
    , public snackBar: MatSnackBar
    , private router: Router
    , private route: ActivatedRoute
    , private titleService: Title
    , public mq: MediaQueryService
    , private rq: RequestService
    , private _ngZone: NgZone
    ) {  }

  private _isXSSM: (s: Alias) =>  boolean = (s) => (s === 'xs') || (s === 'sm');

  private readonly _qpm = this.route.snapshot.queryParamMap;

  private _parseQP(): Params {
    const qp = this._qpm.get('returnQP');
    return qp ? JSON.parse(qp) : {};
  }

  ngOnInit() {
    this.titleService.setTitle(`Bank: login`);
    this.localFlexOrder = 3;
    switch (this.route.snapshot.url[0].path) {
      case 'migrate':
        this._migrate();
        break;
      case 'login':
        switch (this.route.snapshot.paramMap.get('provider')) {
          case 'local':
            this.localFlexOrder = 1;
            if (this._isXSSM(this.mq.alias)) this.tabgroupInitial = 1;
            break;
          case 'google':
            this.loginGoogle(false);
            break;
          case 'facebook':
            this.loginFacebook(false);
            break;
        }
        break;
      case 'reset':
        this.tabgroupInitial = this._isXSSM(this.mq.alias) ? 2 : 1;
        break;
      case 'signup':
        this.tabgroupInitial = this._isXSSM(this.mq.alias) ? 3 : 2;
        break;
    }
    if (!!(this.returnUrl = this._qpm.get('returnUrl') ?? '')) {
      if (this.authService.isAuth) this.returnNow();
      this._initSub = authState(this._afAuth).subscribe(user => {
        // console.log('login.comp ngOnInit() subscription on auth returns: ', user);
        if (user && this.returnUrl) {
          if (!this.signingUp) {
            this.returnNow();
            /*
              * if returnNow() ever returns,
              *   don't bother to stay subscribed to auth changes any more
              */
            if (this._initSub) {
              this._initSub.unsubscribe();
              this._initSub = null;
            }
          }
        }
      });
    } else {
      this._ngZone.run(() => this.router.navigate(
        ['/login']
        , {
          queryParams: {
            ...this._parseQP(),
            returnUrl: '/home'
          }
        }
      ));
    }
    this.mqxssm$ = this.mq.alias$.pipe(
      map(this._isXSSM)
      , distinctUntilChanged()
    );
  }

  /*
   * sign up will suppress the automatic go back until it is finished
   * when done, calls returnNow() if applicable
   */

  private returnNow() {
    if (this.returnUrl) {
      // console.log(`login going back to ${this.returnUrl} with QP=`, JSON.parse(QPM.get('returnQP')));
      this._ngZone.run(() => this.router.navigate(
        [this.returnUrl.split('?')[0]]
        , {queryParams: this._parseQP()}
      ));
      // can't remember why I thought the split() was needed
      // not done in account component
    }
  }

  public subSignin(f: NgForm) {
    const data = f.value as RequiredByKeys<INameMailPwd, 'mail' | 'pwd'>;
    signInWithEmailAndPassword(this._afAuth, data.mail, data.pwd).then(
      () => {
        // if I simply do a model.pwd='' here,
        // change detection won't update the form

        // this.mailComponent.resetForm();
        // f.form.controls['pwd'].reset();
        // f.form.markAsPristine();

        // @ViewChild('mailLogin')
        // private mailComponent: MailComponent;
        // this.mailComponent.resetForm();
        // On 2/6/2017, this reset leads to several problems
        //   onloadwff.js:75 Uncaught TypeError: e.getBoundingClientRect is not a function
        //   mail/pwd fields gone red, like they are in error (though the fields are empty)

        this.snackBar.open('Sign in successful', undefined, { duration: 3000 });
        if (!this.returnUrl) this._ngZone.run(() => this.router.navigate(['/home']));
      }
      , (reason) => {
        this.snackBar.open('Login incorrect', undefined, { duration: 3000 });
        console.warn('login failed: ' + JSON.stringify(reason));
      }
    );
  }

  private _migrate() {
    if (!this.route.snapshot.queryParamMap.has('jwt')) return;
    this.usePopUp = false;
    switch (this.route.snapshot.paramMap.get('provider')) {
      case 'google':
        this.loginGoogle(true);
        break;
      case 'facebook':
        this.loginFacebook(true);
        break;
      default:
        console.error('unknown provider requested');
        return;
    }
  }

  public loginGoogle(migrate: boolean) {
    const p = new GoogleAuthProvider();
    p.addScope('email');
    p.addScope('profile');
    if (this.usePopUp) this.loginFedPopup(p, migrate);
    else this.loginFedRedirect(p, migrate);
  }

  public loginFacebook(migrate: boolean) {
    const p = new FacebookAuthProvider();
    p.addScope('email');
    if (this.usePopUp) this.loginFedPopup(p, migrate);
    else this.loginFedRedirect(p, migrate);
  }

  private loginFedPopup(p: AuthProvider, migrate: boolean) {
    // if sign with redirect, then() is never executed
    // this._afAuth.auth.signInWithRedirect(p).then(value => {
    // Sign in with popup:
    if (this.route.snapshot.queryParamMap.has('jwt')) migrate = true;
    signInWithPopup(this._afAuth, p).then(() => {
      // console.log('login successful: ' + JSON.stringify(this.authService.user));
      if (migrate) {
        this.rq.request('migrate', {
          time: Math.floor(Date.now() / 1000),
          jwt: this.route.snapshot.queryParamMap.get('jwt')
          // token is generated by bank:migrate-federate.php
          // 'host' => $_SERVER["HTTP_HOST"],
          // 'id' => 'TCH',
          // 'mid' => $mid,
          // 'aid' => $aid,
          // 'name' => $name,
          // 'migrate' => $social
        }).then(_ => {
          this._ngZone.run(() => this.router.navigate(['home']));
        });
      }
      this.returnNow();
      this._ngZone.run(() => this.router.navigate(['home']));
    }, (reason: FirebaseError) => {
      this.snackBar.open(`Login failed: ${reason.message}`, 'OK');
      console.warn('login failed: ' + JSON.stringify(reason));
    });
  }

  private loginFedRedirect(p: AuthProvider, migrate: boolean) {
    signInWithRedirect(this._afAuth, p);
    getRedirectResult(this._afAuth).then(() => {
      if (migrate) {
        this.rq.request('migrate', {
          time: Math.floor(Date.now() / 1000),
          jwt: this.route.snapshot.queryParamMap.get('jwt')
        }).then(_ => {
          this._ngZone.run(() => this.router.navigate(['home']));
        });
      }
      // redirecting here is useless
      // firebase will redirect to original URL after it is done
      // that is why I need to set URL oninit to /login?returnURL=/home
      // this.returnNow();
      // this._ngZone.run(() => this.router.navigate(['home']));
      // this.router.navigate(['home']);
    }, (reason: FirebaseError) => {
      this.snackBar.open(`Login failed: ${reason.message}`, 'OK');
      console.log('login failed: ' + JSON.stringify(reason));
    });
  }

  public subReset(f: NgForm) {
    const mail = (f.value as RequiredByKeys<INameMailPwd, 'mail'>).mail;
    this.snackBar.open(`Reset password for {{mail}}`, undefined, { duration: 3000 });
    // const auth = this._afAuth.auth;
    // console.log(mail);
    // auth.fetchProvidersForEmail(mail).then(p => { console.log(p); });
    // deprecated, should use fetchSignInMethodsForEmail(mail)
    // only useful when supports multiple accounts for an email
    sendPasswordResetEmail(this._afAuth, mail).then(() => {
      this.snackBar.open(`Mail issued to reset password.`, undefined, { duration: 3000 });
    }, (error: FirebaseError) => {
      this.snackBar.open(`Reset password failed.`, 'OK');
      console.error(error);
    });
  }

  public subSignup(f: NgForm) {
    const model = f.value as Required<INameMailPwd>;
    this.signingUp = true;
    /*
     * If I don't suppress returnNow()
     *   until I have updated user name,
     *   I would have exit component as soon as the user is created.
     *   The .updateProfile() will never have a chance to execute.
     * I think it is true, because leaving the component should unsubscribe things.
     */
    createUserWithEmailAndPassword(this._afAuth, model.mail, model.pwd)
    .then((userCred: UserCredential) => {
      updateProfile(userCred.user, {
        displayName: model.name,
        photoURL: ''
        // not passing photoURL will keep it unchanged,
        // but typings require both object properties to be present
        // but the user is freshly created so it is empty
        // maybe I should use undefined
      }).then(() => {
        // console.log('then clause of update profile, displayName= ', userCred.user.displayName, model.name);
        this.snackBar.open(`Account created successfully`, undefined, { duration: 3000 });
        this.authService.nameChanged();
        this.signingUp = false;
      }, (error: FirebaseError) => {
        this.snackBar.open(`Account created but name update failed: ${error.code}.`, 'OK');
        console.error(error);
        this.signingUp = false;
      });
      if (this.returnUrl) this.returnNow();
    }, (error: FirebaseError) => {
      this.snackBar.open(`Sign up failed: ${error.message}.`, 'OK');
      console.error(error);
      this.signingUp = false;
    });
  }

  ngOnDestroy() {
    if (this._initSub) this._initSub.unsubscribe();
  }

}
