import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanLoad,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Route,
  UrlTree
} from '@angular/router';
// import { User } from '@firebase/auth-types';
// import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Auth, authState } from '@angular/fire/auth';

import { take } from 'rxjs/operators';

import { GuardService } from './guard.service';
import { AuthService } from 'app/core/auth.service';

@Injectable()
export class AdminGuardService implements CanActivate, CanLoad {
  constructor(
    private router: Router
    , private _afAuth: Auth
    , private _gs: GuardService
    , private _as: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return new Promise<boolean | UrlTree>((resolve) => {
      authState(this._afAuth).pipe(take(1)).subscribe((fbuser) => {
        if (fbuser) {
          this._as.customClaim(fbuser)
          .then(claim => {
            // console.log('admin guard resolved: ', claim.admin);
            if (claim.admin) resolve(true);
            else {
              // authenticated but not admin
              resolve(this._gs.notYetAuth(this.router, route, '/home'));
            }
          }).catch(() => resolve(this._gs.notYetAuth(this.router, route, '/home')));
        } else {
          // not yet authenticated
          resolve(this._gs.notYetAuth(this.router, route));
        }
      });
    });
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean|UrlTree> {
    // console.log('canActivateChild', this._gs.r2url(route));
    return this.canActivate(route, state);
  }

  canLoad(_route: Route): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      authState(this._afAuth).pipe(take(1)).subscribe((fbuser) => {
        if (fbuser) {
          this._as.customClaim(fbuser)
          .then(claim => resolve(!!claim.admin))
          .catch(_ => resolve(false));
        } else {
          // not yet authenticated
          resolve(false);
        }
      });
    });
  }

}
