import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

// import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Database, ref, listVal, objectVal } from '@angular/fire/database';

import { Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { lightFormat } from 'date-fns';

import { AuthService } from 'app/core/auth.service';

import { environment } from 'environments/environment';
import * as uatVer from 'app/shared/version-uat';
import * as prodVer from 'app/shared/version-prod';
import type { Ibankuser } from 'app/app.model';

// /bankuser/{aid}
// the full interface is available in stmt-list.component.ts
// here is the subset of fields used in home.component.ts
// interface Ibankuser {
//   aid: number;
//   addr: string;
//   active: boolean;
//   balancePayable: number;
//   duedate: string;
//   latest: string;
// }
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {

  public bankuser$!: Observable<Ibankuser>;
  public survey$!: Observable<boolean>;
  // whether to display the invitation to survey
  public today!: string;
  public osusers$!: Observable<Ibankuser[]>;
  private _destroy$ = new Subject<boolean>();

  public readonly versions = environment.production ? prodVer.versions : uatVer.versions;

  constructor(
    private titleService: Title
    , private _afDB: Database
    , public authService: AuthService
    , private _router: Router
  ) {   }

  ngOnInit() {
    this.titleService.setTitle(`Bank: Home`);
    this.today = lightFormat(new Date(), 'yyyy-MM-dd');
    // Home is only accessible when mid is present
    // user cannot change mid, only logoff and login again
    // logging off will be routed to /login and home destroyed.
    const mid = this.authService.user.mid;
    // this.bankuser$ = this._afDB.object<Ibankuser>(`/bankuser/${mid}`).valueChanges().pipe(takeUntil(this._destroy$));
    this.bankuser$ = objectVal<Ibankuser>(ref(this._afDB, `/bankuser/${mid}`)).pipe(takeUntil(this._destroy$));
    // this.survey$ = this._afDB.object<string>(`/survey/root/week`).valueChanges().pipe(
    this.survey$ = objectVal<string>(ref(this._afDB, `/survey/root/week`)).pipe(
      takeUntil(this._destroy$),
      switchMap((week: string) => {
        if (!week) return of(false);
        // week lost, suppress message
        if (this.today > week) return of(false);
        // week started, don't bother
        // return this._afDB.object<string[]>(`/survey/${week}/vote/${mid}`).valueChanges()
        return objectVal<string[]>(ref(this._afDB, `/survey/${week}/vote/${mid}`))
          .pipe(
            takeUntil(this._destroy$),
            map((votes: string[]) => votes ? false : true)
          );
      })
    );
    if (this.authService.user.isAdmin) {
      // this.osusers$ = this._afDB.list<Ibankuser>(`/bankuser`).valueChanges().pipe(
      this.osusers$ = listVal<Ibankuser>(ref(this._afDB, `/bankuser`)).pipe(
        takeUntil(this._destroy$),
        map(bubu => (bubu??[]).filter(bu => bu.active && bu.balancePayable < 0 &&  bu.aid > 1))
      );
    }

  }

  public goStmt(yyyymm: string, aid?: number) {
    if (aid) {
      this._router.navigate(['/stmt', yyyymm], {queryParams: {aid}});
    } else {
      this._router.navigate(['/stmt', yyyymm]);
    }
  }

  public goSurvey() {
    this._router.navigate(['/survey']);
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

}
