import { Component, OnInit, Input, Output, EventEmitter, forwardRef, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MediaObserver } from '@angular/flex-layout';

import { lightFormat } from 'date-fns';

@Component({
  selector: 'my-datepicker',
  templateUrl: './my-datepicker.component.html',
  styles: [`
    i.material-icons.disabled {
      opacity: 0.38
    }
  `],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MyDatepickerComponent),
      multi: true
    }
  ]
})
export class MyDatepickerComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder = ''; // used by the template only
  @Input('date') get inDate(): string { return this._sDate; };
  set inDate(d: string | Date | null) {
    // this._inDate = new Date(d.valueOf());
    // no need to clone a copy, because I'm not touching it
    if (this.isDisabled) return;
    if (d === undefined) {
      // console.log('datepicker set undefined');
      this.dDate = undefined;
      this._sDate = '';
      this.dateChange.emit('');
      return;
    }
    if (d === null) {
      // this happens on initialisation
      // console.warn('datepicker set null');
      this.dDate = undefined;
      this._sDate = '';
      this.dateChange.emit('');
      return;
    }
    if (typeof d === 'string') {
      if (d) this._dDateSet(this.sDate2dDate(d), false);
      else { // empty string
        this.dDate = undefined;
        this._sDate = '';
        this.dateChange.emit('');
      }
    } else {
      this._dDateSet(d, false);
    }
    // what to do with undefined and null?
    // acting on it will kill everybody
    // this.dDateSet((d === null || d === undefined) ? new Date() : d);
  }

  @Output() dateChange: EventEmitter<string> = new EventEmitter();

  @ViewChild('picker', { static: true }) elPicker!: MatDatepicker<Date>;
  @ViewChild('in', { static: true }) elIn!: ElementRef<HTMLInputElement>;
  public touchUI = false;
  // starting @angular/material@2.0.0-beta.10
  // <input mdDatepicker> only accepts date type values
  // _sDate is only used to detect during dateset
  // whether the ymd date is changed or not
  private _sDate = '';
  public dDate: Date | undefined;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('disabled') isDisabled = false;

  constructor(
    private _mo: MediaObserver
    // private _media$: ObservableMedia
  ) { }

  ngOnInit() {
    this.touchUI = this._mo.isActive('lt-md');
  }

  writeValue(obj: Date | string): void {
    this.inDate = obj; // invokes the setter
  }

  private _propagateChange = (_v: string) => { };

  public propagateTouch = () => { };

  registerOnChange(fn: () => void): void {
    this._propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.propagateTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public ymd(d: Date): string | undefined {
    if (d === null) return '';
    if (d === undefined) return undefined;
    if (isNaN(d.valueOf())) return '';
    return lightFormat(d, 'yyyy-MM-dd');
  }

  /**
   * sDate2dDate
   *
   * @param sDate a yyyy-mm-dd string
   * @returns a Date object
   */
  public sDate2dDate(sDate: string): Date {
    return new Date(`${sDate}T00:00:00`);
    // result will be wrong if the h:m:s is not provided
  }

  private _dDateSet(d: Date, isUI: boolean) {
    // change the month that picker will display when it opens
    // update the <input> control to ymd format
    if (this.isDisabled) return;

    const ymd = this.ymd(d);
    // console.log('_dDateSet', d, isUI, ymd, this._sDate, this.dDate, this.elPicker._datepickerInput?.value);
    // if (this._sDate === ymd) return;
    if (this.dDate === d) return;
    this.dDate = d;

    if (!ymd) return;
    this.dateChange.emit(this._sDate = ymd);
    // console.log('emit: ', this.dDate, this._sDate);
    if (isUI) this._propagateChange(ymd);
  }

  public dDateSetUI(d: Date) {
    // console.log('dDateSetUI', d, this._sDate, this.dDate, this.elPicker._datepickerInput?.value)
    if (this.dDate !== d) this._dDateSet(d, true);
    this.propagateTouch();
  }

  // no longer used in @angular/material@2.0.0-beta.10
  // public sDateSetUI(p: MdDatepicker<Date>) {
  //   this.dDateSetUI(p._selected);
  // }

  // new for @angular/material@2.0.0-beta.10
  public inDateSetUI(_p: MatDatepicker<Date | undefined>, e: MatDatepickerInputEvent<Date>) {
    // this.dDateSetUI(p._selected);
    if (!e.value) return;
    this.dDateSetUI(e.value);
  }

  public dateAdd(days: number) {
    // p is no longer used
    if (this.isDisabled) return;
    if (!this._sDate) return;
    // const d = new Date(p._selected);
    const d = this.sDate2dDate(this._sDate);
    d.setDate(d.getDate() + days);
    this.dDateSetUI(d);
  }

  public today() {
    this.dDateSetUI(new Date());
  }

}


