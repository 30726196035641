import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from 'app/app-routing/auth-guard.service';

import { LoginComponent } from './login/login.component';
import { AccountComponent } from './account/account.component';

const routes: Routes = [
  {
    path: 'login'
    , component: LoginComponent
  }
  , {
    path: 'login/:provider'
    , component: LoginComponent
  }
  , {
    path: 'migrate/:provider'
    , component: LoginComponent
  }
  , {
    path: 'signup'
    , component: LoginComponent
  }
  , {
    path: 'reset'
    , component: LoginComponent
  }
  , {
    path: 'account'
    , component: AccountComponent
    , canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [
    CommonModule
    , RouterModule.forChild(routes)
  ]
  , exports: [ RouterModule ]
})
export class AuthRoutingModule { }
